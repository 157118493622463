import React from 'react'
import Link from 'gatsby-link'
import ScanningContainer from '../../components/ScanningContainer'
import CrossImpact from '../../components/CrossImpact'
import Drivers from '../../components/Drivers'
import DriverState from '../../components/DriverState'
import DriversSTEEP from '../../components/DriversSTEEP'
import HeaderMain from '../../components/Header'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Header, Icon, List, Divider, Item } from 'semantic-ui-react'
import './style.css'
import baselinePic from '../../assets/images/baseline-pic.jpg'



class DriverPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drivers: [],
            driverState: 'baseline',
            activeDriverNumber: 1
        }
        this.incrementActiveDriverNumber = this.incrementActiveDriverNumber.bind(this);
        this.decrementActiveDriverNumber = this.decrementActiveDriverNumber.bind(this);
}


    componentDidMount() {
        this.getDrivers();
    }

    getDrivers() {
        fetch("https://api.airtable.com/v0/app1POYiufgjnmVCa/Drivers?maxRecords=10&view=Grid%20view", {
         headers: {"Authorization": `Bearer ${process.env.GATSBY_AT_KEY}`}
       }).then((response) => response.json())
           .then((responseData) => {
               const drivers = responseData.records
               this.setState({drivers}, () => {
                   // console.log(this.state.drivers);
               })
         })
    }

    displayDrivers() {

    }

    incrementActiveDriverNumber() {
         console.log(this.state.activeDriverNumber)
        if (this.state.activeDriverNumber > this.state.drivers.length - 1) {
            this.setState({activeDriverNumber: 1}, () => {
                // console.log(this.state.activeDriverNumber, this.state.drivers.length)
            })
        } else {
            this.setState({activeDriverNumber: this.state.activeDriverNumber + 1}, () => {
                    // console.log(this.state.activeDriverNumber, this.state.drivers.length)
            })
        }
    }

    decrementActiveDriverNumber() {
        // console.log(this.state.activeDriverNumber)
        if (this.state.activeDriverNumber <= 1) {
            this.setState({activeDriverNumber: this.state.drivers.length}, () => {
                console.log(this.state.activeDriverNumber, this.state.drivers.length)
            })
        } else {
            this.setState({activeDriverNumber: this.state.activeDriverNumber - 1}, () => {
                console.log(this.state.activeDriverNumber, this.state.drivers.length)
            })
        }
    }

    changeDriverState(x) {
        this.setState({
            driverState: x
        }, () => {
            console.log(this.state.driverState)
        })

    }


    activeDriverClass() {
        //if (this.state.activeDriver == "baseline" ? "driver-filter-wrapper active-driver" : "driver-filter-wrapper"})
        // {this.state.activeDriver ? ""}
    }
    selectDriver(driver) {
        console.log('select driver', this.state.activeDriverNumber, driver)
        this.setState({
            activeDriverNumber: driver.fields.number
        })
    }
    render () {
        const drivers = this.state.drivers.map((driver) => 
            <div className={(this.state.activeDriverNumber == driver.fields.number) ? "driver-filter-wrapper super-driver active-super-driver" : "driver-filter-wrapper super-driver"} onClick={() => this.selectDriver(driver)}>
                <div className="driver-filter-header">{driver.fields.name}</div>
            </div>
        ) 
        
        console.log('drivers', drivers)
        return (
            <div className="driver-page">
            {/* <HeaderMain /> */}
                <div className="">
                    {/* <div className="driver-page-header">
                        <h2>Drivers</h2>
                        <p>Drivers are the key variables that effect our domain and help us create potential futures and scenarios. Below is a list of the key drivers in the death domain and a brief baseline description of each. You can also use the filter to view each driver through the lense of four different states:
                         </p>
                    </div> */}

                  

            

                    <div className="driver-container " >
                  
                        <Drivers drivers={this.state.drivers} activeDriver={this.state.driverState} activeDriverNumber={this.state.activeDriverNumber}/>

                    </div>
                    
                    <div className="driver-super-menu">
                    <div className="driver-navigation">
                        <div className="driver-nav-wrapper" onClick={this.decrementActiveDriverNumber.bind(this)}>
                            <Icon name="left arrow" size="large" /> <span className="driver-nav-text">Prev. Driver</span> 
                        </div>
                        <div className="driver-nav-wrapper"  onClick={this.incrementActiveDriverNumber.bind(this)}>
                            <span className="driver-nav-text">Next Driver</span> <Icon name="right arrow" size="large" /> 
                        </div>
                    </div>


                      <div className="change-state-wrapper">
                        <div className={ this.state.driverState === 'baseline' ? 'driver-filter-wrapper active-driver baseline-grid' : 'driver-filter-wrapper baseline-grid' } onClick={this.changeDriverState.bind(this, "baseline")}>
                            {/* <Icon name="home" size="large" /> */}
                            <div className="driver-filter-header">Baseline</div>
                        </div>
                        <div className={ this.state.driverState === 'continuation' ? 'driver-filter-wrapper active-driver' : 'driver-filter-wrapper' } onClick={this.changeDriverState.bind(this, "continuation")}>
                            {/* <Icon name="chart line" size="large"/> */}
                            <div className="driver-filter-header">Continuation</div>
                        </div>
                        <div  className={ this.state.driverState === 'collapsed' ? 'driver-filter-wrapper active-driver' : 'driver-filter-wrapper' } onClick={this.changeDriverState.bind(this, "collapsed")}>
                            {/* <Icon name="warning circle" size="large" /> */}
                            <div className="driver-filter-header">Collapse</div>
                            </div>
                        <div className={ this.state.driverState === 'newEq' ? 'driver-filter-wrapper active-driver' : 'driver-filter-wrapper' } onClick={this.changeDriverState.bind(this, "newEq")}>
                            {/* <Icon name="balance" size="large"/> */}
                            <div className="driver-filter-header">New Eq</div>
                        </div>
                        <div className={ this.state.driverState === 'transformative' ? 'driver-filter-wrapper active-driver' : 'driver-filter-wrapper' } onClick={this.changeDriverState.bind(this, "transformative")}>
                            {/* <Icon name="recycle" size="large"/> */}
                            <div className="driver-filter-header">Transformative</div>
                        </div>
                        {drivers}
                    </div>

                    <DriverState
                        driverState={this.state.driverState}
                        increment = {this.incrementActiveDriverNumber }
                        decrement = {this.decrementActiveDriverNumber }
                    />
                </div>

                </div>
            </div>
        )
    }
}


export default DriverPage
