import React from 'react'
import Link from 'gatsby-link'
import ScanningHit from '../ScanningHit'
import './drivers.css'
import { Header ,Icon, Divider, Item } from 'semantic-ui-react'

class DriversSTEEP extends React.Component {
    state = {
        activeDriver:'baseline'
    }

    componentDidMount() {
        // this.props
    }


    render () {
        return (
            <div>
                <Header textAlign="center" className="section-header">Drivers through STEEP</Header>
                <div className="bl-steep-container">
                    <Item className="bl-grid-item">
                        <div className="bl-left-wrapper purple">
                            <Icon name="group" size="huge"/>
                            <div className="bl-driver-header">Key drivers and inputs </div>
                            <ul>
                                <li className="bold">Religion</li>
                                <li>Fear of death</li>
                                <li>Grieving process</li>
                            </ul>
                        </div>
                    <Item.Content>
                        <Header as="h4">Social</Header>
                        <p>
                            Our relationship with religion has traditionally been a key driver in the death space, both in preparing the body for the afterlife and funeral rites and rituals, to the role that religion plays in lawmaking. Additionally, in America, we have fostered a culture of individualism that has created a fear of death. As Americans identify less as explicitly religious how will this effect our approach to death as a society?
                        </p>
                    </Item.Content>
                    </Item>
                    <Item className="bl-grid-item">
                        <div className="bl-left-wrapper teal">
                            <Icon name="code branch" size="huge"/>
                            <div className="bl-driver-header">Key drivers and inputs </div>
                            <ul>
                                <li className="bold">A.I.</li>
                                <li className="bold">Medical breakthroughs</li>
                                <li>Mind mapping</li>
                                <li>The role of technology in the funeral service industry</li>
                            </ul>

                        </div>
                        <Item.Content>
                            <Header as="h4">Technological</Header>
                            <p>Technology is seeping into all facets of the death industry, one of the most regressive and slow-moving industries in America. Online death preparation, cost transparency, high tech cemetaries, and self-curated digital memorials are just the tip of the iceburg. We are at the doorstep of technological breakthroughs in mind mapping and artificial intelligence that may redefine what it means to be human and thus require us to rethink what it means to live on after death.</p>
                        </Item.Content>
                    </Item>
                    <Item className="bl-grid-item">
                        <div className="bl-left-wrapper green">
                            <Icon name="tree" size="huge"/>
                            <div className="bl-driver-header">Key drivers and inputs </div>
                            <ul>
                                <li className="bold">Green movement</li>
                                <li>Emerging body disposal methods</li>
                                <li>Real estate and cemetaries</li>
                            </ul>
                        </div>
                        <Item.Content>
                        {/* <Header textAlign="center" as="h5">Going Green</Header> */}
                        <Header as="h4">Environmental</Header>
                        <p>The green movement affects everything from the chemicals we use in embalming to what we want to be done with our bodies. More environmentally aware cemeteries and emerging body disposal techniques like body composting and alkaline hydrolysis all show the role “going green” is playing in the death space.</p>
                        </Item.Content>
                    </Item>
                    <Item className="bl-grid-item">
                        <div className="bl-left-wrapper yellow">
                            <Icon name="money" size="huge"/>
                            <div className="bl-driver-header">Key drivers and inputs </div>
                            <ul>
                                <li className="bold">Funeral Costs</li>
                                <li>End of life medical costs</li>
                                <li>Cremation rate</li>
                                <li>Death preparation</li>
                            </ul>
                        </div>
                        <Item.Content>
                        <Header as="h4">Economical</Header>
                        <p>
                            Americans spend on average about $7,500 per funeral/burial. The funeral industry is famous for upselling grieving families for unadvertised and non-transparent products and services. This is on top of exorbitantly high and often unwanted end of death medical expenses. Crestone, Colorado, America’s only open-air cremation site charges between $500-1000 for a cremation ceremony. In some cities bodies stay in the morgue for months because families can’t afford funeral services. Technology can give us transparency around a famously corrupt industry that will, in turn, create lower-priced products and services that can then lead to further disruption in other STEEP categories.
                        </p>
                    </Item.Content>
                    </Item>
                    <div className="bl-grid-item">
                        <div className="bl-left-wrapper blue">
                            <Icon name="law" size="huge"/>
                            <div className="bl-driver-header">Key drivers and inputs </div>
                            <ul>
                                <li className="bold">Laws and regulations</li>
                                <li>The funeral industry lobby</li>
                                <li>The intertwinement of law and religion</li>
                            </ul>
                        </div>
                        <Item.Content>
                            <Header as="h4">Political</Header>
                            <p>
                                Our options for wow we die, and what becomes of our body is in the stranglehold of the American legal system. Recently, a couple tried to open an open-air funeral site on their rural property in Arizona and were shut down by state laws. In Crestone, Colorado, where they were successful it took years of local lobbying. For most of the 20th century, and still, in many states, embalming certification was required by law in order to run a funeral services business. This essentially prevented Muslims from opening funeral service businesses as body desecration is against the Muslim religion. The funeral industry is full of laws that prohibit innovation and bolster a corrupt industry and changing these laws is a leading driver as to where the space moves.
                            </p>
                        </Item.Content>
                    </div>

                </div>
            </div>


        )
    }
}

export default DriversSTEEP
