import React from 'react'
import Link from 'gatsby-link'
import ScanningHit from '../ScanningHit'
import './drivers.css'
import { Header ,Icon, Divider } from 'semantic-ui-react'

class DriverState extends React.Component {
    state = {
        activeDriver:'baseline'
    }

    componentDidMount() {
        // this.props
    }


    render () {
        return (
            <div className="driver-state-content-wrapper ds-2">
                {/* <div className="driver-navigation">
                    <div onClick={this.props.decrement}><Icon name="left arrow" color="white" /> </div>
                    <div onClick={this.props.increment}><Icon name="right arrow" color="white" /></div>
                </div> */}
                <div className="section-content">
                    {this.props.driverState === 'baseline' ?
                    <div className="ds-cw-grid">
                        <h3>Baseline</h3>
                        <p>The baseline effects of this driver, as well as how trends and potential trends regarding the driver.</p>
                    </div> : null }
                    {this.props.driverState === 'continuation' ?
                    <div className="ds-cw-grid">
                        <h3>Continuation</h3>
                        <p>The present trends and forces within the topic continue without any major disruptions or surprises. The system/topic continues along its current trajectory.</p>
                    </div> : null }
                    {this.props.driverState === 'collapsed' ?
                    <div className="ds-cw-grid">
                        <h3>Collapse</h3>
                        <p>The system/topic "breaks" or falls into a state of dysfunction. The established way of doing things no longer works, and there is decline in the "health" of the system/topic. <Link className="scenario-link" to="/futures/collapse">View Scenario</Link></p>
                    </div> : null }
                    {this.props.driverState === 'newEq' ?
                    <div className="ds-cw-grid">
                        <h3> Formation of a new equilibrim</h3>
                        <p>The system/topic is confronted with a major challenge to how it has been operating, and is forced to adapt and comprise in order to "save itself" keep  the basic structure of the current system intact.</p>
                    </div> : null }
                    {this.props.driverState === 'transformative' ?
                    <div className="ds-cw-grid">
                        <h3>Transformative change</h3>
                        <p>Entails fundamental change to the system/topic. The rules of game are "scrapped" and new ways of doing things emerge.  <Link className="scenario-link" to="/futures/transformative">View Scenario</Link></p>

                    </div> : null }
                </div>
            </div>

        )
    }
}

export default DriverState
